
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import AvatarList from "@/components/AvatarList.vue";

export default defineComponent({
  name: "storeChoose",
  components: {
    Header,
    // HeaderModal,
    SearchBar,
    AvatarList,
  },
  data() {
    return {

    };
  },
  props: {
    data: Object,
  },
  created() {},
  mounted() {
  },
  methods: {
    transfer(type: any) {
      console.log(type)
      this.$router.push({
        path: "/createStore",
      });
    }
  },
});
